<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-kakao py-7 py-lg-8 pt-lg-9">
<!--      <div class="container">-->
<!--        <div class="header-body text-center">-->
<!--          <div class="row justify-content-center">-->
<!--            <div class="col-xl-5 col-lg-6 col-md-8 px-5">-->
<!--              <h2 class="text-white">로고를 넣거나, 서비스 이름을 넣는곳!</h2>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-header bg-transparent">
              <div class="row">
                <div class="col"><h3 class="h1 mb-0 text-center">Kakao Biz Message</h3></div>
              </div>
            </div>
            <div class="card-body px-lg-5 py-lg-5 position-relative">
              <small class="text-danger position-absolute" style="top:0.25rem;" v-if="errorMsg">{{errorMsg}}</small>
              <form role="form" class="needs-validation" @submit.prevent="login" name="loginForm">
                <base-input placeholder="아이디 입력"
                            type="text"
                            name="userId"
                            :error="_getError('userId')"
                            v-validate="'required|email'"
                            v-model="form.userId"
                            :maxlength="50"
                            @blur="saveUserId">
                </base-input>

                <base-input placeholder="비밀번호 입력"
                            type="password"
                            name="userPassword"
                            :error="_getError('userPassword')"
                            v-validate="'required'"
                            v-model="form.userPassword"
                            autocomplete="on"
                            :maxlength="20">
                </base-input>
                <div class="row" v-if="otpOptions.isUse">
                  <div class="col-9">
                    <base-input
                      name="otp"
                      :error="_getError('otp')"
                      v-validate="'required'"
                      v-model="form.otp"
                      group>
                      <input placeholder="인증번호 입력"
                          type="text"
                          v-model="form.otp"
                          :maxlength="6"
                          class="form-control">
                      <div class="input-group-append">
                        <button class="btn btn-outline-primary" v-if="otpOptions.progressTime != 0" type="button" @click="confirmOtp">인증</button>
                        <button class="btn btn-outline-primary" v-else type="button" @click="requestOtp">재발송</button>
                      </div>
                    </base-input>
                  </div>
                  <div class="col-3">
                    <div class="text-center" style="margin-top: 0.5rem; margin-right: 1.3rem;">
                      <span class="text-danger">{{this.otpOptions.convertTime}}</span>
                    </div>
                  </div>
                </div>

                <input type="hidden" name="domain" :value="siteCode">

                <div class="row mt-3">
                  <div class="col-6">
                    <base-checkbox v-model="form.rememberMe"
                                   @input="saveUserId">
                      아이디 저장
                    </base-checkbox>
                  </div>
                  <div class="col-6 text-right">
                    <router-link to="/user/find-id"><small>아이디</small></router-link>&nbsp;<small>/</small>&nbsp;
                    <router-link to="/user/find-password"><small>비밀번호</small></router-link><small> 찾기</small>
                  </div>
                </div>

                <div class="text-center">
                  <base-button type="kakao" class="mt-4" wide native-type="submit">로그인</base-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="swal2-container swal2-center swal2-fade swal2-shown" v-if="isPopupOpen">
  <div aria-labelledby="swal2-title" aria-describedby="swal2-content" class="swal2-popup swal2-modal swal2-show" tabindex="-1" role="dialog" aria-live="assertive" aria-modal="true" style="display: flex;">
    <div class="swal2-header">
      <ul class="swal2-progresssteps" style="display: none;"></ul>
      <div class="swal2-icon swal2-info" style="display: none;">
        <span class="swal2-icon-text">i</span>
      </div>
      <div class="swal2-icon swal2-success" style="display: none;">
        <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);"></div>
        <span class="swal2-success-line-tip"></span>
        <span class="swal2-success-line-long"></span>
        <div class="swal2-success-ring"></div>
        <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div>
        <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div>
      </div>
      <img class="swal2-image" style="display: none;">
      <h2 class="swal2-title" id="swal2-title" style="display: flex;">서비스 종료 공지</h2>
      <button style="display: inline-block;" class="swal2-close" type="button" @click="handleClose">×</button>
    </div>
    <div class="swal2-content">
      <div id="swal2-content" style="display: block;">기존 인프라 환경에서 제공되던 WEB 발송 서비스가 종료될 예정입니다. 새로운 인프라로 전환하지 않은 고객사께서는 서비스 종료 이전에 이관을 완료해 주시기 바랍니다. <br>
        <br>
        <div style="text-align:left">- 서비스 종료일: 2024년 11월 22일 <br>
          <br>- 신규 페이지 접속을 위해서는 접근권한 신청이 필요함으로 운영 담당자에게 문의하여 주세요 (kicm.dkt@kakaocorp.com / 031-606-4618) <br>
          <br>- 종료 대상 서비스 <br> (구) 운영 WEB 발송:&nbsp;https://bizmsg-web.kakaoenterprise.com/user/login <br> (구) 검증 WEB 발송:&nbsp;https://stg-user.bizmsg.kakaoenterprise.com/user/login <br>
          <br>- 변경&nbsp;되는 서비스: <br> (신) 운영 WEB 발송:&nbsp;https://web1.dktechinmsg.com/user/login <br> (신) 검증 WEB 발송:&nbsp;https://stg-bizmsg-web.dktechinmsg.com/user/login <br>
          <br>
          <font color="red">
            <b>⚠ 서비스 종료 후에는 기존 서비스에서 발송이 불가합니다.</b>
          </font>
        </div>
      </div>
      <input class="swal2-input" style="display: none;">
      <input type="file" class="swal2-file" style="display: none;">
      <div class="swal2-range" style="display: none;">
        <input type="range">
        <output></output>
      </div>
      <select class="swal2-select" style="display: none;"></select>
      <div class="swal2-radio" style="display: none;"></div>
      <label for="swal2-checkbox" class="swal2-checkbox" style="display: none;">
        <input type="checkbox">
        <span class="swal2-label"></span>
      </label>
      <textarea class="swal2-textarea" style="display: none;"></textarea>
      <div class="swal2-validation-message" id="swal2-validation-message" style="display: none;"></div>
    </div>
    <div class="swal2-actions" style="display:flex;">
      <button type="button" class="swal2-cancel swal2-styled" aria-label="" style="display:hidden;" @click="handleClose">닫기</button>
    </div>
    <div class="swal2-footer" style="display: none;"></div>
  </div>
</div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import VueCookies from 'vue-cookies';
  import commValidatorMixin from '@/shared/mixins/commValidatorMixin';
  import { USER_API_PREFIX, RESULT_CODE, SITE_CODE } from '@/shared/util/const'
  import CryptoJS from 'crypto-js';
  import swal from "sweetalert2";

  Vue.use(VueCookies);

  export default {
    name: 'Login',
    inject: ['$validator'], // vee-validate를 사용하게 위한 주입 진행
    mixins: [ commValidatorMixin , CryptoJS],
    data() {
      return {
        isPopupOpen: false,
        form: {
          userId: '',
          userPassword: '',
          rememberMe: false,
          otp: ''
        },
        otpOptions: {
          isUse: false,
          otpProcess: null,

          defaultTime: 180,
          progressTime: 0,
          convertTime : ''
        },
        errorMsg: undefined
      };
    },
    mounted() {
      this.isPopupOpen = true;
      // 에러 메시지 세팅
      let error = this.$route.query.errorMsg;
      if(error) {
        this.errorMsg = error;
      }

      // 쿠키에 따른 아이디 저장 세팅
      let cookieId = this.$cookies.get('userId');
      if(cookieId) {
        this.form.userId = this.$cookies.get('userId');
      }
      if(this.form.userId) {
        this.form.rememberMe = true;
      } else {
        this.form.rememberMe = false;
      }
    },
    computed: {
      formAction() {
        return `${USER_API_PREFIX}/login-process`;
      },
      siteCode() {
        return SITE_CODE.USER;
      }
    },
    methods: {
      handleClose() {
        this.isPopupOpen = false;
      },
      login() {
        this._validateBeforeSubmit(() => {
          if(this.form.userId != '' && this.form.userPassword != '') {

            const secretKey = this.makeSecretKey(16, this.form.userId);   //암호화 키 생성

            const keyutf = CryptoJS.enc.Utf8.parse(secretKey);
            const ivutf = CryptoJS.enc.Utf8.parse(secretKey);

            const secretPassword = CryptoJS.AES.encrypt(this.form.userPassword, keyutf, { iv: ivutf , keySize: 128 / 32 , padding: CryptoJS.pad.Pkcs7 }).toString();

            const encodeUriUserId = encodeURIComponent(this.form.userId);
            const encodeUriPassword = encodeURIComponent(secretPassword);
            const encodeSiteCode = encodeURIComponent(this.siteCode);
            const encodeOtpCode = encodeURIComponent(this.form.otp);
            const queryParams = `userId=${encodeUriUserId}&userPassword=${encodeUriPassword}&domain=${encodeSiteCode}&otp=${encodeOtpCode}`;

            fetch(`${this.formAction}?${queryParams}`, {method: 'post'})
              .then(response => {
                const targetURL = response.url;
                if (targetURL == undefined) {
                  return;
                }

                const encodeURL = decodeURIComponent(targetURL);
                const isOtpYn = encodeURL.indexOf("OTP+인증이+필요합니다.") != -1;

                if (isOtpYn) {
                  this.otpOptions.isUse = true;
                  this.startOtpTimer();
                  return;
                }

                location.href = targetURL;
              }).catch(error => {
                console.log(error)
                21718029
              })
          }
        });
      },

      /**
       * Otp 재발송
       */
      requestOtp() {
        this.$axios.post(`${USER_API_PREFIX}/otp/request-otp`, {
          userId: this.form.userId
        }).then(res => {
          if(res.data.result.code === RESULT_CODE.FAIL) {
            this.errorMsg = res.data.result.message
          } else {
            // OTP 타이머 시작
            this.otpOptions.isUse = true;
            this.startOtpTimer();
          }
        }).catch(error => {
          console.log(error.response)

        })
      },

      /**
       * OTP 처리 확인
       */
      confirmOtp() {
        let chk = true;
        if (this.checkNull(this.form.otp)) {
          this.alertMsg('OTP 인증번호를 입력해 주세요.')
          chk = false;
        }
        if (this.otpOptions.progressTime == 0) {
          this.alertMsg('인증시간이 초과하였습니다. 재인증을 진행해주세요.')
          chk = false;
        }

        if(chk){
          this.$axios.post(`${USER_API_PREFIX}/otp/confirm-otp`, {
            userId: this.form.userId,
            otp: this.form.otp
          }).then(res => {
            if(res.data.result.code === RESULT_CODE.FAIL) {
              this.alertMsg(res.data.result.message);
              this.model.otp = ''
            }else{
              this.otpOptions.isUse = false;
              clearInterval(this.otpTimer);
            }
          }).catch(error => {
            console.log(error.response)
          })
        }
      },


      /**
       * OTP 타이머 시작
       */
      startOtpTimer() {
        this.otpOptions.progressTime = this.otpOptions.defaultTime;
        this.otpOptions.convertTime = this.convertSecondsToTimeFormat(this.otpOptions.progressTime)

        if (this.otpOptions.otpProcess != null) {
          clearInterval(this.otpOptions.otpProcess);
        }
        this.otpOptions.otpProcess = setInterval(this.convertProgressTime, 1000)
      },

      /**
       * OTP 인증 남은시간 처리
       */
      convertProgressTime() {
        let timer = this.otpOptions.progressTime
        this.otpOptions.progressTime = timer - 1

        // 타이머 초를 분 단위로 변환
        this.otpOptions.convertTime = this.convertSecondsToTimeFormat(this.otpOptions.progressTime)

        // 타이머 종료시 처리
        if(this.otpOptions.progressTime === 0) {
          clearInterval(this.otpOptions.otpProcess)
        }
      },
      /**
       *
       * 초를 분 단위로 변환
       * @param convertSeconds
       */
      convertSecondsToTimeFormat(convertSeconds) {
        let minutes = Math.floor(convertSeconds / 60)
        let seconds = (convertSeconds % 60)
        return minutes + ':' + this.zeroLeftleftPadding(seconds);
      },

      /**
       * 숫자가 1자리수일 때 2자리수로 변환
       * @param target
       * @returns {*}
       */
      zeroLeftleftPadding(target) {
        return target.toString().length === 1 ? ('0' + target) : target.toString()
      },


      makeSecretKey(width, str){
        if(str != null && str != ''){
          let strLength  = str.length;
          if(strLength > 16){
            return str.substring(0 , width);
          }else{
            return str.padEnd(width,'0');
          }
        }
      },

      saveUserId () {
        if (this.form.rememberMe) {
          // 365 day after, expire, '' current path , browser default
          this.$cookies.config(60 * 60 * 24 * 365, '');
          this.$cookies.set('userId', this.form.userId);
        } else {
          this.$cookies.remove('userId');
        }
      },

      /**
       * null check
       */
      checkNull(param){
        if (param === undefined || param === null || '' == param) {
          return true;
        } else {
          return false;
        }
      },

      /**
       * 알럿메시지 출력
       */
      alertMsg(msg , title){
        let titleMsg = title ? title : '경고';
        let type = title ? 'info' : 'warning';
        swal({
          title: titleMsg,
          text: msg,
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-'+type,
          type: type,
          width: 400
        });
      },
    }
  };
</script>

<style>
  .bg-gradient-kakao{
    background : linear-gradient(87deg, #FFCD00 0, #F7F008 100%) !important;
  }
  .bg-kakao{
    background : #FFCD00 !important;
  }
  .bg-kakao-dark{
    background : #433530 !important;
  }
  .fill-kakao{
    background : #433530 !important;
  }
  .btn-kakao{
    background : #FFCD00 !important;
    border-color : #FFCD00 !important;
  }
</style>
